import { axiosCall } from "./requestApi";
import { baseUrl } from "./helper";
import _store from "../store";


export const getCategoryList = async () => {
  try {
    const response = await axiosCall(baseUrl + "category/get-category", "get", "", "", ""
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllStores = async (data) => {
  try {
    const response = await axiosCall(baseUrl + "store/find-all-stores", "post", data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getStoreItems = async (userData) => {
  try {
    const response = await axiosCall(baseUrl + "store/storeItems", "get", "", "", userData
    );
    return response;
  } catch (error) {
    throw error;
  }
};
export const getStoreCategories = async (id) => {
  try {
    const response = await axiosCall(baseUrl + "store/store-categories", "post", id
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getProductDetails = async (id) => {
  try {
    const response = await axiosCall(baseUrl + "store/item-detail", "post", id)
    return response;
  } catch (error) {
    throw error;
  }
}

export const addNewAddress = async (userData) => {
  try {
    const state = _store?.getState();
    const { token } = state?.auth;
    const header = { authorization: token };
    const response = await axiosCall(baseUrl + "user/add-address", "post", userData, header
    );
    return response;
  } catch (error) {
    throw error;
  }
};
export const updateExistingAddress = async (userData) => {
  try {
    const state = _store?.getState();
    const { token } = state?.auth;
    const header = { authorization: token };
    const response = await axiosCall(baseUrl + "user/update-address", "post", userData, header
    );
    return response;
  } catch (error) {
    throw error;
  }
};
export const getAddresses = async (userData) => {
  try {
    const state = _store?.getState();
    const { token } = state?.auth;
    const header = { authorization: token };
    const response = await axiosCall(baseUrl + "user/get-user-address", "get", "", header
    );
    return response;
  } catch (error) {
    throw error;
  }
};


export const calculateCartData = (data) => {
  let DataTotal = [];
  let totalQuantity = 0;
  let totalMRP = 0;
  data?.forEach(element => {
    let quantity = 0;
    let totalCost = 0;
    element.items?.forEach((item) => {
      quantity += Number(item.quantity)
      totalQuantity += Number(item.quantity);
      totalCost += Number(item.quantity) * Number(item.price)
      totalMRP += Number(item.quantity) * Number(item.price)
    })
    if (quantity > 0) DataTotal.push({ id: element.providerId, quantity, totalCost })
  });
  return [DataTotal, { totalQuantity, totalMRP }]
}