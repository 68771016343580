import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  cartItems: [],
  cartData: [],
  cartTotal: {totalQuantity: 0, totalMRP: 0},
  activeCart: "",
  paymentActiveCart: ",",
  initData: {}
};

export const CartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    updateCartData: (state, action) => {
      return {
        ...state,
        cartData: action?.payload[0],
        cartTotal: action?.payload[1]
      };
    },
    updateActiveCart: (state, action) => {
      return {
        ...state,
        activeCart: action?.payload,
      };
    },
    updateCartItems: (state, action) => {
      return {
        ...state,
        cartItems: action?.payload,
      };
    },
    updatePaymentActiveCart: (state, action) => {
      return {
        ...state,
        paymentActiveCart: action?.payload,
      };
    },
    updateInitData: (state, action) => {
      return {
        ...state,
        initData: action?.payload,
      };
    },

  }
});
export default CartSlice.reducer;
export const {
    updateCartItems,
    updateCartData,
    updateActiveCart,
    updateCart,
    updatePaymentActiveCart,
    updateInitData
} = CartSlice.actions;
