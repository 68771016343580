import axios from "axios";

export const axiosCall = async (url, type, data = '', headers = '', params = '') => {
    return new Promise(async (resolve) => {
        if(url === '') {
            resolve(false);
        }
        if(!['get', 'post'].includes(type.toLowerCase())) {
            resolve(false);
        }
        let resp;
        let respError;
        if(type.toLowerCase() === 'get') {
            await axios.get(url, {params: (params !== '' ? params: {}), headers: (headers !== '' ? headers: {})})
            .then((res) => {
                resp = res;
            })
            .catch((err) => {
                respError = err;
            });
        } else if(type.toLowerCase() === 'post') {
            // let formData = new FormData();
            const formData = data;
            await axios?.post(url, formData, {params: (params !== '' ? params: {}), headers: (headers !== '' ? headers : {})})
            .then((res) => {
                resp = res;
            })
            .catch((err) => {
                // console.log(url, 'Got axios post catch error ', err);
                respError = err;
            });
        } else {
            console.log('Unidentified axios request');
        }
        // console.log(resp, "anurag");
        // console.log(respError, "anuragError");
        if(resp && (resp?.status === 200 || resp?.status === 201)) {
            // console.log(resp);
            resolve(resp);
        } else {
            // console.log(resp);
            // resolve(resp);
            // console.log('else resp ', {resp, respError});
            resolve(respError?.response || false);
        }
    });
};
