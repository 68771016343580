import React, { Suspense, useContext, useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Toaster, toast } from 'sonner'
import Splash from './pages/login/Splash';
import "react-toastify/dist/ReactToastify.css";
import GenericSpinner from './components/GenericSpinner';
import _store from './store';
import { Provider } from 'react-redux';
import SearchLocationInput from './components/SearchLocationInput';
import GenericRelatedItems from './components/GenericRelatedItems';
import MyComponent from './components/MyComponent';
import { useLoadScript } from '@react-google-maps/api';
import StepperComponent from './components/StepperComponent.jsx';
const ForgotPassword = React.lazy(() => import('./pages/login/ForgotPassword'));
const Pages = React.lazy(() => import("./pages/Pages.jsx"));
const Headers = React.lazy(() => import("./components/Headers.jsx"));

function App() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCCzIV9BW3gBP2BhLddDCIXfh6bgaHrJsE",
    libraries: ["drawing"],
  });

  return (
    <Provider store={_store}>
      <BrowserRouter>
        <Routes>
          <Route
            path="/*"
            element={
              <div className="App primary-bg-common relative">
                <Suspense fallback={<GenericSpinner type="clip" />}>
                  <Headers />
                  <Pages />
                </Suspense>
              </div>
            }
          />
          <Route path="/splash" element={<Splash />} />
          <Route path="/reset-password" element={<Suspense fallback={<GenericSpinner type="clip" />}><ForgotPassword /></Suspense>} />
          <Route path="/maps" element={<Suspense fallback={<GenericSpinner type="clip" />}><MyComponent /></Suspense>} />
          <Route path="/auto" element={<SearchLocationInput />} />
          <Route path="/generic" element={<GenericRelatedItems />} />
          <Route path="/stepper" element={<StepperComponent />} />
        </Routes>
      </BrowserRouter>
      <Toaster position="top-right" duration={3000} richColors toastOptions={{
        style: { background: '#eae6f3', boxShadow: "1px 1px 8px 8px rgba(115, 92, 173, 0.12)", border: "1px solid rgb(115, 92, 173)" },
      }} />
    </Provider>
  );
}

export default App;
