import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  nearbyStoreSearchType : "Home",
  viewAllNearbyStore: false,
  activeStore: ""
};


export const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    UpdateViewAllNearbyStore: (state, action) => {
      return {
        ...state,
        viewAllNearbyStore: action?.payload,
      };
    },
    UpdateActiveStore: (state, action) => {
      return {
        ...state,
        activeStore: action?.payload
      };
    }
  }
});
export default UserSlice.reducer;
export const {
    UpdateViewAllNearbyStore
} = UserSlice.actions;
