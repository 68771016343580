import React, { useState, useEffect } from 'react'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import SearchLocationInput from './SearchLocationInput';
import { fetchCurrentLocation, getLocationInfo } from '../services/helper';
import axios from 'axios';
import { updateLatLng, updateSelectedPincode } from '../store/slices/AddressSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';



// const center = {
//   lat: 28.745,
//   lng: 76.523
// };

function MyComponent(props) {
  const { getData, height, coord } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addresses } = useSelector((state) => state);
  const { currentLocation, latLng } = addresses;
  const [ selectedLocation, setSelectedLocation] = useState({});
  const [ locationData, setLocationData] = useState({});
  const [firstRender, setFirstRender] = useState(false);
  const [pinCode, setPinCode] = useState("")
  const [display, setDisplay] = useState("");
  const [mapData, setMapData] = useState({})
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyCCzIV9BW3gBP2BhLddDCIXfh6bgaHrJsE"
  });
  const [map, setMap] = React.useState(null);
  const initialZoom = 13;
  const maxZoom = 20;

  const containerStyle = {
    border: "2px solid #969fa3",
    borderRadius: "15px",
    width: '100%',
    height: height ?? '300px',
    cursor: "pointer"
  };



  const getLocationInfo = async (data) => {
    try {
      const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${data?.lat}&lon=${data?.lng}`);
      setLocationData(response?.data)
      const { display_name, address } = response?.data;
      setDisplay(display_name)
      setPinCode(address?.postcode +" - " + (address?.state_district || address?.city))
    } catch (err) {
      console.log('Error fetching location data');
    }
  };

  const currentLocationMarker =async () => {
    try {
      const location = await fetchCurrentLocation();
      const { lat, lng } = location || {};
      setSelectedLocation({lat, lng});
      getLocationInfo({lat, lng})
    } catch (error) {
      console.error("Failed to fetch location:", error);
    }
  }
  

  useEffect(() => {
    if(latLng && Object.keys(latLng)?.length === 0){
      currentLocationMarker();
    }else{
      setSelectedLocation(latLng)
    }
    setFirstRender(true);
  }, [])

  useEffect(() => {
    if(coord?.lat){
      currentLocationMarker();
    }
  }, [coord])
  
  
  useEffect(() => {
    getLocationInfo(selectedLocation);
  }, [selectedLocation])
  
  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    // const bounds = new window.google.maps.LatLngBounds({lat:28.640551, lng:77.224808});
    // map.fitBounds(bounds);
    map.setZoom(initialZoom);
    setMap(map);

    map.addListener('zoom_changed', () => {
      if (map.getZoom() > maxZoom) {
        map.setZoom(maxZoom);
      }
    });
  }, [currentLocation, selectedLocation])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const handleClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    setSelectedLocation({ lat, lng });
  };

  const handleClickUseCurrent = async()=>{
    try {
      const location = await fetchCurrentLocation();
      const { lat, lng } = location || {};
      setSelectedLocation({lat, lng});
      getLocationInfo({lat, lng})
    } catch (error) {
      console.error("Failed to fetch location:", error);
    }
  }

  return isLoaded ? (
    <div className='relative overflow-auto'>
      <button className='primary-bg px-3 py-1 rounded-lg mb-2' onClick={handleClickUseCurrent}>Use Current Location</button>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={selectedLocation}
        zoom={initialZoom}
        // maxZoom={14}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onClick={handleClick}
        // options={{
        //   gestureHandling: 'greedy',
        // }}
      >
        <Marker position={selectedLocation} />
        <SearchLocationInput setSelectedLocation={setSelectedLocation} />
        <></>
      </GoogleMap>
      {display !== "" && (
        <>
          <div className='my-1'>{display}</div>
          <div className='flex justify-center items-center mt-1'>
            <button className='primary-bg px-3 py-1 rounded-lg' onClick={() => {getData({selectedLocation,pinCode, obj: locationData})}}>Confirm</button>
          </div>
        </>
      )}
    </div>
  ) : <></>
}

export default React.memo(MyComponent)