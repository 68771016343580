import React from 'react'
import ondcLogo from '../assets/ondc_logo.svg'
import unnatiLogo from '../assets/unnamed.webp'

function SplashScreen() {
    return (
        <div className='flex flex-col items-center justify-center h-screen overflow-hidden'>
            {/* <span className="loader"> </span> */}
            
            <div className='flex flex-col mb-2'>
                <img className='ml-3' src={unnatiLogo} style={{width : '170px'}} alt=''/>
                <img className='mr-3 mt-5' src={ondcLogo} style={{height : '100px'}} alt=''/>
                
            </div>
            <small>Please wait</small>
            <div className="loader"> 
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
            </div>
        </div>
  )
}

export default SplashScreen