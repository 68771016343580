import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  productCategory: {},
  nearbyStores: [
      {
          name: "Indeekos",
          distance: "2.4",
          discount: "14",
          address: "Tricity Plaza, Peer Muchala, Zirakpur, MohaliPeer Muchalla Rd Sector 20 Sant Kabir Nagar"
      },
      {
          name: "Meekado",
          distance: "2.8",
          discount: "14",
          address: "Citi Plaza, Zirakpur, MohaliPeer Muchalla Rd Sector 20 Sant Kabir Nagar"
      },
      {
          name: "Darshan Lal & Sons",
          distance: "2.8",
          discount: "14",
          address: "Citi Plaza, Zirakpur, MohaliPeer Muchalla Rd Sector 20 Sant Kabir Nagar"
      },
      {
          name: "Suryavit Traders",
          distance: "2.8",
          discount: "14",
          address: "Citi Plaza, Zirakpur, MohaliPeer Muchalla Rd Sector 20 Sant Kabir Nagar"
      },
      {
          name: "Sachin Stores",
          distance: "2.8",
          discount: "14",
          address: "Citi Plaza, Zirakpur, MohaliPeer Muchalla Rd Sector 20 Sant Kabir Nagar"
      },
      {
          name: "Aashirvaad Traders",
          distance: "2.8",
          discount: "14",
          address: "Citi Plaza, Zirakpur, MohaliPeer Muchalla Rd Sector 20 Sant Kabir Nagar"
      },
      {
          name: "Satyajeet Kirana",
          distance: "2.8",
          discount: "14",
          address: "Citi Plaza, Zirakpur, MohaliPeer Muchalla Rd Sector 20 Sant Kabir Nagar"
      },
      {
          name: "Atler Goods",
          distance: "2.8",
          discount: "14",
          address: "Citi Plaza, Zirakpur, MohaliPeer Muchalla Rd Sector 20 Sant Kabir Nagar"
      },
      {
          name: "Suryavit Traders",
          distance: "2.8",
          discount: "14",
          address: "Citi Plaza, Zirakpur, MohaliPeer Muchalla Rd Sector 20 Sant Kabir Nagar"
      },
      {
          name: "Mitthra Oil Mill",
          distance: "2.8",
          discount: "14",
          address: "Citi Plaza, Zirakpur, MohaliPeer Muchalla Rd Sector 20 Sant Kabir Nagar"
      },
      {
          name: "Local Sparrow",
          distance: "2.8",
          discount: "14",
          address: "Citi Plaza, Zirakpur, MohaliPeer Muchalla Rd Sector 20 Sant Kabir Nagar"
      },
      {
          name: "Torrent Pharma",
          distance: "2.8",
          discount: "14",
          address: "Citi Plaza, Zirakpur, MohaliPeer Muchalla Rd Sector 20 Sant Kabir Nagar"
      },
      {
          name: "Nutberry",
          distance: "2.8",
          discount: "14",
          address: "Citi Plaza, Zirakpur, MohaliPeer Muchalla Rd Sector 20 Sant Kabir Nagar"
      },
      {
          name: "Tea Curry Herbals",
          distance: "2.8",
          discount: "14",
          address: "Citi Plaza, Zirakpur, MohaliPeer Muchalla Rd Sector 20 Sant Kabir Nagar"
      },
      {
          name: "Enterro Lucid Traders",
          distance: "2.8",
          discount: "14",
          address: "Citi Plaza, Zirakpur, MohaliPeer Muchalla Rd Sector 20 Sant Kabir Nagar"
      },
      {
          name: "SRR Jewels",
          distance: "2.8",
          discount: "14",
          address: "Citi Plaza, Zirakpur, MohaliPeer Muchalla Rd Sector 20 Sant Kabir Nagar"
      },
      {
          name: "Care Berry",
          distance: "2.8",
          discount: "14",
          address: "Citi Plaza, Zirakpur, MohaliPeer Muchalla Rd Sector 20 Sant Kabir Nagar"
      },
      {
          name: "Womenium Craft",
          distance: "2.8",
          discount: "14",
          address: "Citi Plaza, Zirakpur, MohaliPeer Muchalla Rd Sector 20 Sant Kabir Nagar"
      },
      {
          name: "Uswa Tea",
          distance: "2.8",
          discount: "14",
          address: "Citi Plaza, Zirakpur, MohaliPeer Muchalla Rd Sector 20 Sant Kabir Nagar"
      },
    ],
  active: "Home",
  activeSubCategories: [],
  nearbyStoresDemo: [],
  categoryWiseNearbyStores: [],
  recentStores: []
};
export const CategoriesSlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    updateCategories: (state, action) => {
      let obj = {}
      if (action?.payload) for (let key of action?.payload ){
           if (!obj.hasOwnProperty(key?.category_code)){
              obj[key?.category_code] = { name: key?.category ,subCategory: [key?.sub_category]};
           }else{
              obj[key?.category_code]?.subCategory?.push(key?.sub_category)
           }
        }
      return {
        ...state,
        productCategory: obj,
      };
    },
    updateActiveCategory: (state, action) => {
      return {
        ...state,
        active: action?.payload,
      };
    },
    updateActiveSubCategory: (state, action) => {
      return {
        ...state,
        activeSubCategories: action?.payload,
      };
    },
    updateNearbyStores: (state, action) => {
      return {
        ...state,
        nearbyStoresDemo: action?.payload
      };
    },
    updateCategoryWiseNearbyStores: (state, action) => {
      return {
        ...state,
        categoryWiseNearbyStores: action?.payload
      };
    },
    updateRecentStores: (state, action) => {
      return {
        ...state,
        recentStores: action?.payload
      };
    },
  }
});
export default CategoriesSlice.reducer;
export const {
  updateCategories,
  updateActiveCategory,
  updateNearbyStores,
  updateActiveSubCategory,
  updateCategoryWiseNearbyStores,
  updateRecentStores
} = CategoriesSlice.actions;
