import React from 'react'
import SplashScreen from '../../components/SplashScreen'

function Splash() {
  return (
    <div>
        <SplashScreen/>
    </div>
  )
}

export default Splash