import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  token: '',
  name: '',
  email: '',
  mobile: '',
  loginSuccess: false,
  createdAt: "",
  loginSignupProcess: {},
  openChangePassword: false,
  tokenExpired: false,
};
export const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    UpdateLoginDetailsOnSuccess: (state, action) => {
      const { token, userData } = action?.payload;
      return {
        ...state,
        token: token,
        name: userData?.name,
        email: userData?.email,
        mobile: userData?.phone_number,
        loginSuccess: true,
        createdAt: userData?.created_at
      };
    },
    logOut: (state, action) => {
      return {
        ...initialState
      };
    },
    updateToken: (state, action) => {
      return {
        ...state,
        token: action?.payload
      };
    },
    updateOpenChangePassword: (state, action) => {
      return {
        ...state,
        openChangePassword: action?.payload
      };
    }
    // requestToLogin: (state, action) => {
    //   state.loginRequested = true;
    // },
    // requestToVerifyOTP: (state, action) => {
    //   state.verifyOTPRequest = true;
    // },

    // errorToLogin: (state, action) => {
    //   state.loginError = true;
    // },
    // OTPViaLogin: (state, action) => {
    //   state.loginOTPResponse = action?.payload;
    // },
    // successToLogin: (state, action) => {
    //   state.loginSuccess = action?.payload;
    // },
    // logout: (state, action) => {
    //   return {
    //     ...initialState
    //   };
    // }
  }
});
export default AuthSlice.reducer;
export const {
  UpdateLoginDetailsOnSuccess,
  logOut,
  updateToken,
  updateOpenChangePassword
} = AuthSlice.actions;
