import React, {useEffect, useState} from 'react'
import { getStoreCategories } from '../services/storeData';

function GenericRelatedItems() {
  const [storeCategory, setstoreCategory] = useState([])
  const [arrayy, setArrayy] = useState([]);

  const categoryButton = (evt) =>{
     const {name, checked} = evt?.target;
     let selected = [...arrayy];
     (checked) ? selected?.push(name) : selected = selected.filter((item)=> item!== name)
     setArrayy(selected)
  }

  useEffect(() => {
    (async()=>{
      const storeCategory = await getStoreCategories({ id:"46743249-07f0-4f32-b0de-b6d01ea0a86a" });
            const { data } = storeCategory?.data || {};
            if (data) {
              setstoreCategory(data?.categories);
            }
    })()
  }, [])
  
  return (
    <div>
            {storeCategory?.map((item) => (
                <div className={`flex gap-2 my-1`}>
                    <div>
                        <input className='w-4 transform scale-[1.2]' name={item?.category} onChange={categoryButton} type="checkbox" />
                    </div>
                    <h1>{item?.category}</h1>
                </div>
            ))}
        </div>
  )
}

export default GenericRelatedItems