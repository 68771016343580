import { combineReducers, configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
// import rootSaga from './sagas/rootSagas';
import AuthSlice from './slices/AuthSlice';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import UserSlice from './slices/UserSlice';
import  CategoriesSlice  from './slices/CategoriesSlice';
import CartSlice from './slices/CartSlice';
import AddressSlice from './slices/AddressSlice';

const sagaMiddleware = createSagaMiddleware();
const storeMiddleware = [sagaMiddleware];
const persistConfig = {
  key: 'root',
  storage
};
const appReducer = combineReducers({
  auth: AuthSlice,
  user: UserSlice,
  categories: CategoriesSlice,
  cart: CartSlice,
  addresses: AddressSlice
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const _store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({ 
      serializableCheck: false
    }).concat(storeMiddleware);
  }
});
// sagaMiddleware.run(rootSaga);
export const _persistorStore = persistStore(_store);
export default _store;
