import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  locations: [1,2,3,4],
  deliveryAddresses: [],
  latLng: {},
  addressDisplayed: "",
  currentLocation: {},
  selectedPincode: ""
};

export const AddressSlice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    updateLocations: (state, action) => {
      return {
        ...state,
        locations: action?.payload,
      };
    },
    updateDeliveryAddresses: (state, action) => {
      return {
        ...state,
        deliveryAddresses: action?.payload,
      };
    },
    updateLatLng: (state, action) => {
      return {
        ...state,
        latLng: action?.payload,
      };
    },
    updateAddressDisplayed: (state, action) => {
      return {
        ...state,
        addressDisplayed: action?.payload,
      };
    },
    updateCurrentLocation: (state, action) => {
      return {
        ...state,
        currentLocation: action?.payload,
      };
    },
    updateSelectedPincode: (state, action) => {
      return {
        ...state,
        selectedPincode: action?.payload,
      };
    }
  }
});
export default AddressSlice.reducer;
export const {
    updateLocations,
    updateDeliveryAddresses,
    updateLatLng,
    updateAddressDisplayed,
    updateCurrentLocation,
    updateSelectedPincode
} = AddressSlice.actions;
