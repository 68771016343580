import React from 'react'
import { ClipLoader, PropagateLoader } from 'react-spinners'

function GenericSpinner({type, full}) {
    return (
        <div>
            <div className={`${full? 'top-0': 'md:top-[58px] top-[95px]'}`} style={{
                width: "100vw",
                height: `${full ? '100vh' : '85vh'}`,
                position: "fixed",
                background: `${full ? 'rgba(255,255,255,0.35)' : "rgba(255,255,255,0.5)"}`,
                display: "flex",
                zIndex: "999",
                // top: "4rem",
                left: "0",
                justifyContent: "center",
                alignItems: "center"
            }}>
                {type === "clip" ? (
                    <ClipLoader
                    color="#9080ba"
                    size={60}
                    speedMultiplier={0.8}
                    cssOverride={
                        {borderWidth: "7px"}
                    }
                />
                ):(
                     <PropagateLoader
                        color="#9080ba"
                        zIndex="999"
                        size={20}
                        speedMultiplier={1.5}
                    /> 
                )}
                
            </div>
        </div>
    )
}

export default GenericSpinner