import React from 'react'
import { Stepper } from 'react-form-stepper';

function StepperComponent() {
  return (
    <div>
      <Stepper
        steps={[{ label: 'Step 1' }, { label: 'Step 2' }, { label: 'Step 3' }]}
        activeStep={2}
        connectorStateColors={true}
        connectorStyleConfig={{
          size: 3,
          activeColor: "#000"
        }}
        styleConfig={{
          completed: false,
          active: true,
          label: "hello"
        }}
        size={10}
        activeColor={"#000"}
      />
    </div>
  )
}

export default StepperComponent