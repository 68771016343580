import { toast } from "sonner";
import { axiosCall } from "./requestApi";

// export const baseUrl = "https://buyer-stage-backend.pep1.in/";
export const baseUrl = "https://stage.retailconnect.co.in/preprod/";
// export const baseUrl = "http://localhost:3000/";
export const REACT_APP_GOOGLE_MAPS_KEY = "AIzaSyCCzIV9BW3gBP2BhLddDCIXfh6bgaHrJsE";

export const categoryCode = { "ONDC:RT": "Electronics", "ONDC:RT":"Appliances", "ONDC:RT": "Home & Kitchen" , "ONDC:RT": "Health & Wellness" ,"ONDC:RET10": "Grocery" ,"ONDC:RT": "Beauty & Personal Care","ONDC:RT": "Fashion"}

export const getLocationInfo = async (userData) => {
   try {
     const url = `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${userData?.lat+""}&lon=${userData?.lng+""}`
     const response = await axiosCall(url, "get" 
     );
     return response;
   } catch (error) {
     throw error;
   }
 };

export const fetchCurrentLocation = () => {
   return new Promise((resolve, reject) => {
       if (navigator.geolocation) {
           navigator.geolocation.getCurrentPosition(
               (position) => {
                   const { latitude: lat, longitude: lng } = position.coords;
                   resolve({ lat, lng });
               },
               (error) => {
                   console.error("Error getting location: ", error);
                   toast.error("Error getting location, please check browser's settings");
                   reject(error);
               }
           );
       } else {
           const error = new Error("Geolocation is not supported by this browser.");
           console.error(error.message);
           reject(error);
       }
   });
};


const data = {
    "plus_code" : 
    {
       "compound_code" : "9GR3+QV2 Sikanderpur, Uttar Pradesh, India",
       "global_code" : "7JWV9GR3+QV2"
    },
    "results" : 
    [
       {
          "address_components" : 
          [
             {
                "long_name" : "9GR3+QV",
                "short_name" : "9GR3+QV",
                "types" : 
                [
                   "plus_code"
                ]
             },
             {
                "long_name" : "Sikanderpur",
                "short_name" : "Sikanderpur",
                "types" : 
                [
                   "locality",
                   "political"
                ]
             },
             {
                "long_name" : "Gautam Buddh Nagar",
                "short_name" : "Gautam Buddh Nagar",
                "types" : 
                [
                   "administrative_area_level_3",
                   "political"
                ]
             },
             {
                "long_name" : "Meerut Division",
                "short_name" : "Meerut Division",
                "types" : 
                [
                   "administrative_area_level_2",
                   "political"
                ]
             },
             {
                "long_name" : "Uttar Pradesh",
                "short_name" : "UP",
                "types" : 
                [
                   "administrative_area_level_1",
                   "political"
                ]
             },
             {
                "long_name" : "India",
                "short_name" : "IN",
                "types" : 
                [
                   "country",
                   "political"
                ]
             }
          ],
          "formatted_address" : "9GR3+QV Sikanderpur, Uttar Pradesh, India",
          "geometry" : 
          {
             "bounds" : 
             {
                "northeast" : 
                {
                   "lat" : 28.392,
                   "lng" : 77.50475
                },
                "southwest" : 
                {
                   "lat" : 28.391875,
                   "lng" : 77.50462499999999
                }
             },
             "location" : 
             {
                "lat" : 28.391881,
                "lng" : 77.504649
             },
             "location_type" : "GEOMETRIC_CENTER",
             "viewport" : 
             {
                "northeast" : 
                {
                   "lat" : 28.3932864802915,
                   "lng" : 77.5060364802915
                },
                "southwest" : 
                {
                   "lat" : 28.3905885197085,
                   "lng" : 77.50333851970849
                }
             }
          },
          "place_id" : "GhIJfOwuUFJkPEARYr1RK0xgU0A",
          "plus_code" : 
          {
             "compound_code" : "9GR3+QV Sikanderpur, Uttar Pradesh, India",
             "global_code" : "7JWV9GR3+QV"
          },
          "types" : 
          [
             "plus_code"
          ]
       },
       {
          "address_components" : 
          [
             {
                "long_name" : "9GV4+2M",
                "short_name" : "9GV4+2M",
                "types" : 
                [
                   "plus_code"
                ]
             },
             {
                "long_name" : "Sikanderpur",
                "short_name" : "Sikanderpur",
                "types" : 
                [
                   "locality",
                   "political"
                ]
             },
             {
                "long_name" : "Gautam Buddh Nagar",
                "short_name" : "Gautam Buddh Nagar",
                "types" : 
                [
                   "administrative_area_level_3",
                   "political"
                ]
             },
             {
                "long_name" : "Meerut Division",
                "short_name" : "Meerut Division",
                "types" : 
                [
                   "administrative_area_level_2",
                   "political"
                ]
             },
             {
                "long_name" : "Uttar Pradesh",
                "short_name" : "UP",
                "types" : 
                [
                   "administrative_area_level_1",
                   "political"
                ]
             },
             {
                "long_name" : "India",
                "short_name" : "IN",
                "types" : 
                [
                   "country",
                   "political"
                ]
             },
             {
                "long_name" : "203201",
                "short_name" : "203201",
                "types" : 
                [
                   "postal_code"
                ]
             }
          ],
          "formatted_address" : "9GV4+2M, Sikanderpur, Uttar Pradesh 203201, India",
          "geometry" : 
          {
             "location" : 
             {
                "lat" : 28.3925524,
                "lng" : 77.50669189999999
             },
             "location_type" : "GEOMETRIC_CENTER",
             "viewport" : 
             {
                "northeast" : 
                {
                   "lat" : 28.3939013802915,
                   "lng" : 77.50804088029149
                },
                "southwest" : 
                {
                   "lat" : 28.3912034197085,
                   "lng" : 77.50534291970848
                }
             }
          },
          "place_id" : "ChIJzfs8KwDBDDkR2Yq752lX8Fs",
          "types" : 
          [
             "establishment",
             "point_of_interest"
          ]
       },
       {
          "address_components" : 
          [
             {
                "long_name" : "Unnamed Road",
                "short_name" : "Unnamed Road",
                "types" : 
                [
                   "route"
                ]
             },
             {
                "long_name" : "Sikanderpur",
                "short_name" : "Sikanderpur",
                "types" : 
                [
                   "locality",
                   "political"
                ]
             },
             {
                "long_name" : "Gautam Buddh Nagar",
                "short_name" : "Gautam Buddh Nagar",
                "types" : 
                [
                   "administrative_area_level_3",
                   "political"
                ]
             },
             {
                "long_name" : "Meerut Division",
                "short_name" : "Meerut Division",
                "types" : 
                [
                   "administrative_area_level_2",
                   "political"
                ]
             },
             {
                "long_name" : "Uttar Pradesh",
                "short_name" : "UP",
                "types" : 
                [
                   "administrative_area_level_1",
                   "political"
                ]
             },
             {
                "long_name" : "India",
                "short_name" : "IN",
                "types" : 
                [
                   "country",
                   "political"
                ]
             },
             {
                "long_name" : "203201",
                "short_name" : "203201",
                "types" : 
                [
                   "postal_code"
                ]
             }
          ],
          "formatted_address" : "Unnamed Road, Sikanderpur, Uttar Pradesh 203201, India",
          "geometry" : 
          {
             "bounds" : 
             {
                "northeast" : 
                {
                   "lat" : 28.3918985,
                   "lng" : 77.5046955
                },
                "southwest" : 
                {
                   "lat" : 28.3916965,
                   "lng" : 77.50427049999999
                }
             },
             "location" : 
             {
                "lat" : 28.3918016,
                "lng" : 77.5044807
             },
             "location_type" : "GEOMETRIC_CENTER",
             "viewport" : 
             {
                "northeast" : 
                {
                   "lat" : 28.3931464802915,
                   "lng" : 77.5058319802915
                },
                "southwest" : 
                {
                   "lat" : 28.3904485197085,
                   "lng" : 77.50313401970848
                }
             }
          },
          "place_id" : "ChIJh3OerTzBDDkR8HTnjfkkxl8",
          "types" : 
          [
             "route"
          ]
       },
       {
          "address_components" : 
          [
             {
                "long_name" : "Sikanderpur",
                "short_name" : "Sikanderpur",
                "types" : 
                [
                   "locality",
                   "political"
                ]
             },
             {
                "long_name" : "Gautam Buddh Nagar",
                "short_name" : "Gautam Buddh Nagar",
                "types" : 
                [
                   "administrative_area_level_3",
                   "political"
                ]
             },
             {
                "long_name" : "Meerut Division",
                "short_name" : "Meerut Division",
                "types" : 
                [
                   "administrative_area_level_2",
                   "political"
                ]
             },
             {
                "long_name" : "Uttar Pradesh",
                "short_name" : "UP",
                "types" : 
                [
                   "administrative_area_level_1",
                   "political"
                ]
             },
             {
                "long_name" : "India",
                "short_name" : "IN",
                "types" : 
                [
                   "country",
                   "political"
                ]
             }
          ],
          "formatted_address" : "Sikanderpur, Uttar Pradesh, India",
          "geometry" : 
          {
             "bounds" : 
             {
                "northeast" : 
                {
                   "lat" : 28.4089399,
                   "lng" : 77.51245999999999
                },
                "southwest" : 
                {
                   "lat" : 28.38771,
                   "lng" : 77.47700999999999
                }
             },
             "location" : 
             {
                "lat" : 28.3998708,
                "lng" : 77.4960078
             },
             "location_type" : "APPROXIMATE",
             "viewport" : 
             {
                "northeast" : 
                {
                   "lat" : 28.4089399,
                   "lng" : 77.51245999999999
                },
                "southwest" : 
                {
                   "lat" : 28.38771,
                   "lng" : 77.47700999999999
                }
             }
          },
          "place_id" : "ChIJj3ErFRXBDDkRTVcyMlz_LyY",
          "types" : 
          [
             "locality",
             "political"
          ]
       },
       {
          "address_components" : 
          [
             {
                "long_name" : "203201",
                "short_name" : "203201",
                "types" : 
                [
                   "postal_code"
                ]
             },
             {
                "long_name" : "Salarpur",
                "short_name" : "Salarpur",
                "types" : 
                [
                   "locality",
                   "political"
                ]
             },
             {
                "long_name" : "India",
                "short_name" : "IN",
                "types" : 
                [
                   "country",
                   "political"
                ]
             }
          ],
          "formatted_address" : "Salarpur, 203201, India",
          "geometry" : 
          {
             "bounds" : 
             {
                "northeast" : 
                {
                   "lat" : 28.395923,
                   "lng" : 77.6672998
                },
                "southwest" : 
                {
                   "lat" : 28.2446106,
                   "lng" : 77.4656997
                }
             },
             "location" : 
             {
                "lat" : 28.354998,
                "lng" : 77.53901270000001
             },
             "location_type" : "APPROXIMATE",
             "viewport" : 
             {
                "northeast" : 
                {
                   "lat" : 28.395923,
                   "lng" : 77.6672998
                },
                "southwest" : 
                {
                   "lat" : 28.2446106,
                   "lng" : 77.4656997
                }
             }
          },
          "place_id" : "ChIJvdvqIHe4DDkRT0c43bUuLcY",
          "types" : 
          [
             "postal_code"
          ]
       },
       {
          "address_components" : 
          [
             {
                "long_name" : "Gautambudhnagar",
                "short_name" : "Gautambudhnagar",
                "types" : 
                [
                   "administrative_area_level_4",
                   "political"
                ]
             },
             {
                "long_name" : "Gautam Buddh Nagar",
                "short_name" : "Gautam Buddh Nagar",
                "types" : 
                [
                   "administrative_area_level_3",
                   "political"
                ]
             },
             {
                "long_name" : "Meerut Division",
                "short_name" : "Meerut Division",
                "types" : 
                [
                   "administrative_area_level_2",
                   "political"
                ]
             },
             {
                "long_name" : "Uttar Pradesh",
                "short_name" : "UP",
                "types" : 
                [
                   "administrative_area_level_1",
                   "political"
                ]
             },
             {
                "long_name" : "India",
                "short_name" : "IN",
                "types" : 
                [
                   "country",
                   "political"
                ]
             }
          ],
          "formatted_address" : "Gautambudhnagar, Uttar Pradesh, India",
          "geometry" : 
          {
             "bounds" : 
             {
                "northeast" : 
                {
                   "lat" : 28.5401576,
                   "lng" : 77.7379186
                },
                "southwest" : 
                {
                   "lat" : 28.2473836,
                   "lng" : 77.3528187
                }
             },
             "location" : 
             {
                "lat" : 28.3592653,
                "lng" : 77.55274690000002
             },
             "location_type" : "APPROXIMATE",
             "viewport" : 
             {
                "northeast" : 
                {
                   "lat" : 28.5401576,
                   "lng" : 77.7379186
                },
                "southwest" : 
                {
                   "lat" : 28.2473836,
                   "lng" : 77.3528187
                }
             }
          },
          "place_id" : "ChIJkW7q2rvADDkRn7PQcu2ahvA",
          "types" : 
          [
             "administrative_area_level_4",
             "political"
          ]
       },
       {
          "address_components" : 
          [
             {
                "long_name" : "Gautam Buddh Nagar",
                "short_name" : "Gautam Buddh Nagar",
                "types" : 
                [
                   "administrative_area_level_3",
                   "political"
                ]
             },
             {
                "long_name" : "Meerut Division",
                "short_name" : "Meerut Division",
                "types" : 
                [
                   "administrative_area_level_2",
                   "political"
                ]
             },
             {
                "long_name" : "Uttar Pradesh",
                "short_name" : "UP",
                "types" : 
                [
                   "administrative_area_level_1",
                   "political"
                ]
             },
             {
                "long_name" : "India",
                "short_name" : "IN",
                "types" : 
                [
                   "country",
                   "political"
                ]
             }
          ],
          "formatted_address" : "Gautam Buddh Nagar, Uttar Pradesh, India",
          "geometry" : 
          {
             "bounds" : 
             {
                "northeast" : 
                {
                   "lat" : 28.6544428,
                   "lng" : 77.7379186
                },
                "southwest" : 
                {
                   "lat" : 28.0851174,
                   "lng" : 77.29709430000001
                }
             },
             "location" : 
             {
                "lat" : 28.338333,
                "lng" : 77.6077865
             },
             "location_type" : "APPROXIMATE",
             "viewport" : 
             {
                "northeast" : 
                {
                   "lat" : 28.6544428,
                   "lng" : 77.7379186
                },
                "southwest" : 
                {
                   "lat" : 28.0851174,
                   "lng" : 77.29709430000001
                }
             }
          },
          "place_id" : "ChIJZc0VBFPkDDkRaCE3BhJhWPQ",
          "types" : 
          [
             "administrative_area_level_3",
             "political"
          ]
       },
       {
          "address_components" : 
          [
             {
                "long_name" : "Meerut Division",
                "short_name" : "Meerut Division",
                "types" : 
                [
                   "administrative_area_level_2",
                   "political"
                ]
             },
             {
                "long_name" : "Uttar Pradesh",
                "short_name" : "UP",
                "types" : 
                [
                   "administrative_area_level_1",
                   "political"
                ]
             },
             {
                "long_name" : "India",
                "short_name" : "IN",
                "types" : 
                [
                   "country",
                   "political"
                ]
             }
          ],
          "formatted_address" : "Meerut Division, Uttar Pradesh, India",
          "geometry" : 
          {
             "bounds" : 
             {
                "northeast" : 
                {
                   "lat" : 29.2981478,
                   "lng" : 78.4774759
                },
                "southwest" : 
                {
                   "lat" : 28.059198,
                   "lng" : 77.1314141
                }
             },
             "location" : 
             {
                "lat" : 28.67267529999999,
                "lng" : 77.7452081
             },
             "location_type" : "APPROXIMATE",
             "viewport" : 
             {
                "northeast" : 
                {
                   "lat" : 29.2981478,
                   "lng" : 78.4774759
                },
                "southwest" : 
                {
                   "lat" : 28.059198,
                   "lng" : 77.1314141
                }
             }
          },
          "place_id" : "ChIJNxYh8FJJDDkRf2gpybOtkXk",
          "types" : 
          [
             "administrative_area_level_2",
             "political"
          ]
       },
       {
          "address_components" : 
          [
             {
                "long_name" : "Uttar Pradesh",
                "short_name" : "UP",
                "types" : 
                [
                   "administrative_area_level_1",
                   "political"
                ]
             },
             {
                "long_name" : "India",
                "short_name" : "IN",
                "types" : 
                [
                   "country",
                   "political"
                ]
             }
          ],
          "formatted_address" : "Uttar Pradesh, India",
          "geometry" : 
          {
             "bounds" : 
             {
                "northeast" : 
                {
                   "lat" : 30.4062757,
                   "lng" : 84.63466009999999
                },
                "southwest" : 
                {
                   "lat" : 23.8701098,
                   "lng" : 77.08394819999999
                }
             },
             "location" : 
             {
                "lat" : 27.5705886,
                "lng" : 80.0981869
             },
             "location_type" : "APPROXIMATE",
             "viewport" : 
             {
                "northeast" : 
                {
                   "lat" : 30.4062757,
                   "lng" : 84.63466009999999
                },
                "southwest" : 
                {
                   "lat" : 23.8701098,
                   "lng" : 77.08394819999999
                }
             }
          },
          "place_id" : "ChIJ0wlKe59OmTkRnSQXNm1HpfY",
          "types" : 
          [
             "administrative_area_level_1",
             "political"
          ]
       },
       {
          "address_components" : 
          [
             {
                "long_name" : "India",
                "short_name" : "IN",
                "types" : 
                [
                   "country",
                   "political"
                ]
             }
          ],
          "formatted_address" : "India",
          "geometry" : 
          {
             "bounds" : 
             {
                "northeast" : 
                {
                   "lat" : 35.6733149,
                   "lng" : 97.39535869999999
                },
                "southwest" : 
                {
                   "lat" : 6.4626999,
                   "lng" : 68.1097
                }
             },
             "location" : 
             {
                "lat" : 20.593684,
                "lng" : 78.96288
             },
             "location_type" : "APPROXIMATE",
             "viewport" : 
             {
                "northeast" : 
                {
                   "lat" : 35.6733149,
                   "lng" : 97.39535869999999
                },
                "southwest" : 
                {
                   "lat" : 6.4626999,
                   "lng" : 68.1097
                }
             }
          },
          "place_id" : "ChIJkbeSa_BfYzARphNChaFPjNc",
          "types" : 
          [
             "country",
             "political"
          ]
       }
    ],
    "status" : "OK"
 }


export const reasonsToCancel = [
   {
       "code" : "001",
       "reason" : "Price of one or more items have changed due to which buyer was asked to make additional payment"
   },
   {
       "code" : "003",
       "reason" : "Product available at lower than order price"
   },
   {
       "code" : "004",
       "reason" : "Store is not accepting order"
   },
   {
       "code" : "006",
       "reason" : "Order / fulfillment not received as per buyer app TAT SLA"
   },
   {
       "code" : "009",
       "reason" : "Wrong product delivered"
   },
   {
       "code" : "010",
       "reason" : "Buyer wants to modify address / other order details"
   },
   {
       "code" : "011",
       "reason" : "Buyer not found or cannot be contacted"
   },
   {
       "code" : "012",
       "reason" : "Buyer does not want product any more"
   },
   {
       "code" : "013",
       "reason" : "Buyer refused to accept delivery"
   },
   {
       "code" : "014",
       "reason" : "Address not found"
   },
   {
       "code" : "015",
       "reason" : "Buyer not available at location"
   }
];

// eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MywiaWF0IjoxNzI1MDIwNDI4LCJleHAiOjE3MjUxMDY4Mjh9.WASfQhHwI2MnFCgCZC9HNpdEI4wPoW9H5vytCCaQOvY

// eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MywiaWF0IjoxNzI0OTMxNjc1LCJleHAiOjE3MjUwMTgwNzV9.sg3N4BACpPnI497H3IG_7hpuqQ3PKDIecQ7AzqWOqd4